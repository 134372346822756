import { useContext } from 'react';
import { useQuery } from 'react-apollo';

import { SessionContext } from 'contexts/session';

import { queryOptions } from 'utils/helper';
import { useSuperAdmin } from 'hooks';

import FIND_ALL_OKTA_GROUPS from '../../queries/userManagement/findAllOktaGroups';
import FIND_ALL_OKTA_GROUPS_IN_ADMIN_SCOPE from '../../queries/userManagement/findAllOktaGroupsInAdminScope';

const useAllGroups = () => {
  const { user, targetUser } = useContext(SessionContext);

  let FIND_GROUPS = FIND_ALL_OKTA_GROUPS;
  let variables = {};
  const {
    isEverlywellSuperAdmin,
    canManageUsers,
    isMasqueradeMode,
    targetClientIds,
  } = useSuperAdmin();

  if (!!isEverlywellSuperAdmin && !!isMasqueradeMode && !!canManageUsers) {
    FIND_GROUPS = FIND_ALL_OKTA_GROUPS_IN_ADMIN_SCOPE;
    variables = {
      client_ids: targetClientIds,
      partner_id: parseInt(targetUser.enterprise_partner_id)
    };
  }

  return useQuery(FIND_GROUPS, {
    ...queryOptions(user),
    variables,
  });
};

export default useAllGroups
