import React, { useState, useEffect } from 'react';

import { isBrowser } from 'utils/environment';
import { STORAGE_REDIRECT, DEFAULT_REDIRECT } from 'utils/constants';
import { updateDataDogUserContext } from 'utils/datadog';

import { useHasuraClaims } from 'hooks';

/** The only LoggedOutUser, the AnonymousUser */
export const AnonymousUser = {
  isLoggedIn: false,
  name: '',
  email: '',
  idToken: {},
  group: {},
};

export const DefaultTargetUser = {
  email: null,
  role: null,
  enterprise_partner_id: null,
  enterprise_client_ids: [],
  access_code_manager: 'false',
  can_manage_eligibility_files: 'false',
  can_register_kits: 'false',
  can_manage_users: 'false',
  getstream_token: null
};

export const SessionContext = React.createContext({
  isLoading: true,
  user: AnonymousUser,
  clients: [],
  clientNames: [],
  partnerName: null,
  isLoadedClients: undefined,
  targetUser: DefaultTargetUser,
  setUser: () => { },
  setClients: () => { },
  setClientNames: () => { },
  setPartnerName: () => { },
  setLoadedClients: () => { },
  setTargetUser: () => { },
});

/**
 * The SessionProvider maintains user authentication state and provides it to the app
 * via the context API. Auth0-related functions are proxied to the Auth service singleton.
 */
export const SessionProvider = ({ children }) => {
  const [sessionState, setSessionState] = useState({
    clients: [],
    clientNames: [],
    partnerName: null,
    isLoading: true,
    user: AnonymousUser,
    isLoadedClients: false,
    targetUser: DefaultTargetUser,
  });

  const claims = useHasuraClaims();

  const setUser = (user) =>
    setSessionState({ ...sessionState, isLoading: false, user });

  const hasClients =
    sessionState.isLoadedClients && sessionState.clients.length > 0;

  const setClients = (clients) => {
    setSessionState({
      ...sessionState,
      isLoadedClients: true,
      clients,
    });
  };

  useEffect(() => {
    updateDataDogUserContext(sessionState);
  }, [sessionState]);

  const setLoadedClients = (isLoadedClients) => {
    setSessionState({ ...sessionState, isLoadedClients });
  };

  const setTargetUser = (targetUser) => {
    setSessionState({ ...sessionState, targetUser });
  };

  const setClientNames = (namesList) => {
    setSessionState({
      ...sessionState,
      clientNames: namesList,
    });
  };

  const setPartnerName = (name) => {
    setSessionState({
      ...sessionState,
      partnerName: name,
    });
  };

  return (
    <SessionContext.Provider
      value={{
        ...sessionState,
        hasClients,
        setUser,
        setClients,
        setTargetUser,
        setClientNames,
        setPartnerName,
        setLoadedClients,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export const setRedirect = (redirect) => {
  if (isBrowser) {
    window.localStorage.setItem(STORAGE_REDIRECT, redirect);
  }
};

export const getRedirect = () => {
  if (isBrowser) {
    return window.localStorage.getItem(STORAGE_REDIRECT) || DEFAULT_REDIRECT;
  }
  return '/';
};
