import gql from "graphql-tag"

const DELETE_USER = gql`
  mutation deleteOktaUser($oktaId: String!) {
    deleteOktaUser(okta_id: $oktaId) {
      okta_id
      status
      error
    }
  }
`

export default DELETE_USER
