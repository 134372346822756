import { gql } from "@apollo/client"

const getIdsWhereClause = ({ hasExternalTest }) => hasExternalTest ? "_or: [{id: {_in: $ids}}, { id: {_is_null: true} }]" : "id: { _in: $ids }"

const getEnterprisePartnerWhereClause = ({ partner_id }) => partner_id ? 'enterprise_partner_id:{_eq:$partner_id}' : ''

const getEnterpriseClientWhereClause = ({ client_ids }) => client_ids ? 'enterprise_client_id:{_in:$client_ids}' : ''

const getTestCountWithEnterpriseFilter = vars =>
  gql`
    query TestCount($ids: [Int!], $partner_id: Int, $client_ids: [Int!], $hasExternalTest: Boolean!) {
      tests: tests_names_view(where: { ${getIdsWhereClause(vars)} }) {
        id
        display_name
        kit_results_aggregate(
          where: {
            ${getEnterprisePartnerWhereClause(vars)}
            ${getEnterpriseClientWhereClause(vars)}
          }
        ) {
          aggregate {
            count
          }
        }
        external_kit_results_aggregate(
          where: {
            ${getEnterprisePartnerWhereClause(vars)}
            ${getEnterpriseClientWhereClause(vars)}
            test_id: { _is_null: true }
          }
        ) @include(if: $hasExternalTest) {
          aggregate {
            count
          }
        }
      }
    }
  `

const getTestCountWithoutEnterpriseFilter = (vars) =>
  gql`
    query TestCount($ids: [Int!], $hasExternalTest: Boolean!) {
      tests: tests_names_view(where: { ${getIdsWhereClause(vars)} }) {
        id
        display_name
        kit_results_aggregate {
          aggregate {
            count
          }
        }
        external_kit_results_aggregate(where: { test_id: { _is_null: true } }) @include(if: $hasExternalTest) {
          aggregate {
            count
          }
        }
      }
    }
  `

const GET_TEST_COUNT = vars => (vars?.partner_id || vars?.client_ids) ? getTestCountWithEnterpriseFilter(vars) : getTestCountWithoutEnterpriseFilter(vars)

export default GET_TEST_COUNT