import { useContext } from "react"
import { useMutation } from "react-apollo"

import { SessionContext } from "contexts/session"

import { queryOptions } from "utils/helper"

import RESET_MULTIFACTOR from "../../graphql/UserManagement/mutations/resetMultifactor"

const useResetMultifactorMutation = options => {
  const { user } = useContext(SessionContext)

  return useMutation(RESET_MULTIFACTOR, {
    ...queryOptions(user, true),
    ...options,
    refetchQueries: ['FindOneUser'],
  });
}

export default useResetMultifactorMutation
