import { useContext } from "react"
import { useMutation } from "react-apollo"

import { SessionContext } from "contexts/session"

import { queryOptions } from "utils/helper"

import UPDATE_USER_GROUP from "../../graphql/UserManagement/mutations/updateUserGroup"

const useUpdateUserGroupMutation = options => {
  const { user } = useContext(SessionContext)

  return useMutation(UPDATE_USER_GROUP, {
    ...queryOptions(user, true),
    ...options,
    refetchQueries: ['FindOneUser'],
  });
}

export default useUpdateUserGroupMutation
