import { useContext } from "react"
import { useQuery } from "react-apollo"

import { SessionContext } from "contexts/session"
import { queryOptions } from 'utils/helper';

import FIND_ALL_USER_LOGS from "../../queries/userManagement/findAllUserLogs"

const SIZE = 1000

const useAllUserLogs = ({ actorId }) => {
  const { user } = useContext(SessionContext);
  
  return useQuery(FIND_ALL_USER_LOGS, {
    ...queryOptions(user),
    variables: { actorId, size: SIZE },
    skip: !actorId,
  });
};

export default useAllUserLogs
