import { moment, getTimezoneOffset } from "./common"

const FORMAT = "M/D/YY"

const diffTime = time => {
  const offset = getTimezoneOffset()

  const old = moment(time + "Z").utcOffset(offset) // prevent auto timezone setting
  const now = moment().utcOffset(offset)

  const diffHour = now.diff(old, "hour")
  if (diffHour >= 24) {
    return old.format(FORMAT)
  }

  const diffMin = now.diff(old, "minute")
  if (diffMin >= 60) {
    return diffHour + "h"
  }

  const diffSec = now.diff(old, "second")
  if (diffSec >= 60) {
    return diffMin + "m"
  }

  return diffSec + "s"
}

const reverseDiffTime = time => {
  const offset = getTimezoneOffset()

  const old = moment(time).utcOffset(offset)
  const now = moment().utcOffset(offset)

  const diffHour = old.diff(now, "hour")
  if (diffHour >= 24) {
    return old.format(FORMAT)
  }

  const diffMin = old.diff(now, "minute")
  if (diffMin >= 60) {
    return diffHour + "h"
  }

  const diffSec = old.diff(now, "second")
  if (diffSec >= 60) {
    return diffMin + "m"
  }

  return diffSec + "s"
}

// Checks if datetime is older than the current moment in time
// returns Boolean
const dateHasPassed = time => {
  const offset = getTimezoneOffset()

  const exp = moment(time).utcOffset(offset)
  const now = moment().utcOffset(offset)

  return exp < now
}

export {
  diffTime,
  reverseDiffTime,
  dateHasPassed
}
