import { gql } from "@apollo/client"

const GET_TEST_IDS = vars =>
  vars.partner_id || vars.client_ids
    ? gql`
        query TestIds($partner_id: Int, $client_ids: [Int!]) {
          testIds: lens_kit_results_view(
            distinct_on: test_id
            where: {
              ${
                vars?.partner_id
                  ? `enterprise_partner_id:{_eq:$partner_id}`
                  : ""
              }
              ${
                vars?.client_ids ? `enterprise_client_id:{_in:$client_ids}` : ""
              }
            }
          ) {
            id
            test_id
            test_display_name
          }
        }
      `
    : gql`
        query TestIds {
          testIds: lens_kit_results_view(distinct_on: test_id) {
            id
            test_id
          }
        }
      `

export default GET_TEST_IDS
