export const MENU_WIDTH_IN_PERCENTAGE = 3;
export const DEFAULT_COLUMN_COUNT = 9;
export const FAKE_CELL_W_PC = 10;

export default {
  selection: {
    index: 0,
    name: 'Selection',
    minWidth: 24,
    maxWidth: 56,
  },
  client: {
    index: 1,
    name: 'Client',
    minWidth: 85,
  },
  id: {
    index: 2,
    name: 'Kit ID',
    minWidth: 121,
  },
  status: {
    index: 3,
    name: 'Kit Status',
    minWidth: 140,
  },
  name: {
    index: 4,
    name: 'Participant',
    minWidth: 110,
  },
  email: {
    name: 'Email',
    minWidth: 197,
  },
  dob: {
    name: 'Date Of Birth',
    minWidth: 100,
  },
  phone: {
    name: 'Phone',
    minWidth: 118,
  },
  thirdPartyMember: {
    name: 'Third Party Member ID',
    minWidth: 81,
  },
  ordered: {
    name: 'Ordered',
    minWidth: 105,
  },
  registerTime: {
    index: 5,
    name: 'Registered',
    minWidth: 115,
  },
  collectTime: {
    name: 'Sample Collected',
    minWidth: 115,
  },
  resultsEntered: {
    name: 'Results Entered',
    minWidth: 106,
  },
  resultsEnteredAt: {
    name: 'Results Entered At',
    minWidth: 115,
  },
  receiveTime: {
    index: 6,
    name: 'Received by Lab',
    minWidth: 92,
  },
  issue: {
    index: 7,
    name: 'Sample Issue',
    minWidth: 132,
  },
  approveTime: {
    index: 8,
    name: 'Results Released',
    minWidth: 102,
  },
  participantViewedAt: {
    name: 'Participant Viewed at',
    minWidth: 117,
  },
  test: {
    index: 9,
    name: 'Test Name',
    minWidth: 145,
  },
  result: {
    index: 10,
    name: 'Result',
    minWidth: 106,
  },
  menu: {
    index: 11,
    width: MENU_WIDTH_IN_PERCENTAGE,
  },
  firstName: {
    name: 'First Name',
  },
  lastName: {
    name: 'Last Name',
  },
  programName: {
    index: 11,
    name: 'Program Name',
    minWidth: 145,
  },
};
