import { useContext } from "react"
import { useQuery } from "react-apollo"

import { SessionContext } from "contexts/session"
import { queryOptions } from 'utils/helper';

import { useSuperAdmin } from 'hooks';
import FIND_ALL_OKTA_USERS from '../../queries/userManagement/findAllUsers';
import FIND_ALL_OKTA_USERS_IN_ADMIN_SCOPE from "../../queries/userManagement/findAllOktaUsersInAdminScope";

const useAllUsers = () => {
  const { user, targetUser } = useContext(SessionContext);

  let FIND_USERS = FIND_ALL_OKTA_USERS;
  let variables = {};
  const {
    isEverlywellSuperAdmin,
    canManageUsers,
    isMasqueradeMode,
    targetClientIds
  } = useSuperAdmin();
  
  if (!!isEverlywellSuperAdmin && !!isMasqueradeMode && !!canManageUsers) {
    FIND_USERS = FIND_ALL_OKTA_USERS_IN_ADMIN_SCOPE;
    variables = {
      client_ids: targetClientIds,
      partner_id: parseInt(targetUser.enterprise_partner_id),
    } 
  }

  return useQuery(FIND_USERS, {
    ...queryOptions(user),
    variables,
  });
};

export default useAllUsers
