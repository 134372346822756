import { useMutation } from "react-apollo"

import { queryOptions } from "utils/helper"

import FETCH_ELIGIBILITY_IMPORT from "../graphql/EligibilityFile/mutations/fetchEligibilityImport"

const useFetchEligibilityImportMutation = ({ user = {}, variables, onCompleted }) => {
  return useMutation(FETCH_ELIGIBILITY_IMPORT, {
    ...queryOptions(user, true),
    variables,
    onCompleted,
  })
}

export default useFetchEligibilityImportMutation
