import mixpanel from "mixpanel-browser"

let initialized = false

const init = () =>
  new Promise((resolve, reject) => {
    if (process.env.GATSBY_MIXPANEL_PROJECT_TOKEN) {
      if (!initialized) {
        mixpanel.init(process.env.GATSBY_MIXPANEL_PROJECT_TOKEN)
        initialized = true
        // @ts-ignore
        window.mixpanel = mixpanel
      }

      return resolve()
    }

    return reject()
  })

const baseUrlData = () => {
  let urlData = {}
  if (document.title) urlData.title = document.title
  if (document.referrer) urlData.referrer = document.referrer
  if (window.location.search) urlData.search = window.location.search
  if (window.location.href) urlData.url = window.location.href
  if (window.location.pathname) urlData.path = window.location.pathname

  return urlData
}

export const track = (eventName, data) => {
  return init()
    .then(() => {
      mixpanel.track(eventName, Object.assign({}, data, baseUrlData()))
      return Promise.resolve()
    })
    .catch(() => Promise.resolve())
}

export default {
  track,
}
