import { useContext } from "react"
import { useLazyQuery } from "react-apollo"

import { SessionContext } from "contexts/session"
import { queryOptions } from 'utils/helper';

import FIND_ONE_GROUP_BY_CONFIGRUATION from "../../queries/userManagement/findOneGroupByConfiguration"

const useOneGroupByConfiguration = ({
  enterprisePartnerID,
  role,
  handleCompleted,
}) => {
  const { user } = useContext(SessionContext);

  return useLazyQuery(FIND_ONE_GROUP_BY_CONFIGRUATION, {
    ...queryOptions(user),
    skip: !enterprisePartnerID || !role,
    onCompleted: handleCompleted,
  });
}

export default useOneGroupByConfiguration
