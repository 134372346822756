import { useEffect, useContext } from 'react';

import {
  useLDClient,
  useFlags as useBaseFlags,
} from 'gatsby-plugin-launchdarkly';

import { useSuperAdmin } from 'hooks';

import { useHasuraClaims } from 'hooks';
import { SessionContext } from 'contexts/session';

const useFlags = () => {
  const flags = useBaseFlags();
  const ldClient = useLDClient();

  const { user } = useContext(SessionContext);

  const { currentUserId, enterprisePartnerId } = useHasuraClaims();
  const { isEverlywellSuperAdmin, isMasqueradeMode, targetUserPartnerId } =
    useSuperAdmin();

  // Gatsby camelcases the flag names
  const isKitsDashboardEnabled = flags["enterpriseKitsDashboardEnabled"];

  const identifyUser = () => {
    try {
      ldClient.identify({
        key: user.email,
        custom: {
          enterprisePartnerId:
            isEverlywellSuperAdmin && isMasqueradeMode
              ? targetUserPartnerId
              : enterprisePartnerId,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!currentUserId) return;
    identifyUser();
  }, [currentUserId, targetUserPartnerId]);

  return {
    isKitsDashboardEnabled,
  };
};

export default useFlags;