import { useState, useContext } from "react"

import { HASURA_ROLE } from "utils/constants"
import { SessionContext } from "contexts/session"

import {
  useHasuraClaims,
  usePartnerConfig,
  useSuperAdmin,
} from "hooks"

const usePaidAccessCodeStatus = () => {
  const {
    user,
    targetUser: {
      enterprise_partner_id: partner_id
    }
  } = useContext(SessionContext) || {}

  const { isEnterprisePartnerClinicalAdmin } = useHasuraClaims()
  const { isEverlywellSuperAdminPartner } = useSuperAdmin()
  const { data } = usePartnerConfig(user, { partner_id })

  const partnerPaidAccessCodesConfig = data
    ?.enterprise_partner_configs[0]
    ?.paid_access_codes_enabled

  const isPartnerAdmin = isEnterprisePartnerClinicalAdmin || isEverlywellSuperAdminPartner
  const shouldEnablePaidAccessCodes = isPartnerAdmin ? partnerPaidAccessCodesConfig : false

  const [paidAccessCodesEnabled, setPaidAccessCodesEnabled] = useState(shouldEnablePaidAccessCodes)

  const handlePaidAccessCodesChange = (client) => {
    const hasPaidAccessCodesEnabled = client?.enterprise_partner_configs[0]?.paid_access_codes_enabled
    const updatedValue = hasPaidAccessCodesEnabled ?? shouldEnablePaidAccessCodes

    setPaidAccessCodesEnabled(updatedValue)
  }

  return {
    paidAccessCodesEnabled,
    handlePaidAccessCodesChange,
  }
}

export default usePaidAccessCodeStatus
