import gql from "graphql-tag"

const SET_TEMPORARY_PASSWORD = gql`
  mutation setTemporaryOktaUserPassword($oktaId: String!) {
    setTemporaryOktaUserPassword(okta_id: $oktaId) {
      okta_id
      status: user_status
      error
      temporary_password
    }
  }
`

export default SET_TEMPORARY_PASSWORD
