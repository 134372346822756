import { gql } from "@apollo/client"

const FIND_ONE_USER = gql`
  query FindOneUser($oktaId: String!) {
    oktaUser: getOktaUser(userId: $oktaId) {
      profile {
        firstName
        lastName
        email
        canViewRapidTests: x_can_view_rapid_tests
        canManageAccessCodes: x_hasura_access_code_manager
        canManageUsers: x_hasura_can_manage_users
        canRegisterKits: x_hasura_can_register_kits
      }
      oktaId: id
      oktaStatus: status
    }
    oktaGroups: getOktaUserGroups(userId: $oktaId) {
      id
      type
      profile {
        name
      }
      details: okta_group_view {
        role
        partnerName: partner_name
        clientNames: client_names
      }
    }
  }
`;

export default FIND_ONE_USER
