import { gql } from "@apollo/client"

const FIND_ONE_GROUP_BY_NAME = gql`
  query FindUserGroupByName($name: String!) {
    findUserGroupByName: okta_groups(where: { name: { _like: $name } }) {
      id: okta_id
    }
  }
`;

export default FIND_ONE_GROUP_BY_NAME
