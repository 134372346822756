import { gql } from '@apollo/client';

const GET_TARGET_USER_DETAILS = gql`
  query GetTargetUserDetails($oktaId: String!) {
    getOktaUser(userId: $oktaId) {
      id
      profile {
        email
        access_code_manager: x_hasura_access_code_manager
        can_manage_users: x_hasura_can_manage_users
        can_manage_eligibility_files: x_hasura_can_manage_eligibility_files
        can_register_kits: x_hasura_can_register_kits
      }
    }
    okta_user_view(where: { okta_id: { _eq: $oktaId } }) {
      role: group_role
      enterprise_partner_id
      enterprise_client_ids
    }
    getStreamTokenForOktaUser(okta_user_id: $oktaId) {
      getstream_token: token
    }
  }
`;

export default GET_TARGET_USER_DETAILS;
