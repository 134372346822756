import { gql } from "@apollo/client"

const GET_PROGRAM_NAMES = vars =>
  vars.partner_id || vars.client_ids
    ? gql`
        query ProgramNames($partner_id: Int, $client_ids: [Int!]) {
          programNames: lens_kit_results_view(
            distinct_on: enterprise_program_name
            where: {
              ${
                vars?.partner_id
                  ? `enterprise_partner_id:{_eq:$partner_id}`
                  : ""
              }
              ${
                vars?.client_ids ? `enterprise_client_id:{_in:$client_ids}` : ""
              }
            }
          ) {
            enterprise_program_name
          }
        }
      `
    : gql`
        query ProgramNames {
          programNames: lens_kit_results_view(distinct_on: enterprise_program_name) {
            enterprise_program_name
          }
        }
      `

export default GET_PROGRAM_NAMES
