import { useMutation } from "react-apollo"

import { queryOptions } from "utils/helper"

import FETCH_ALL_ELIGIBILITY_IMPORTS_PAGINATED from "../graphql/EligibilityFile/mutations/fetchEligibilityImportsPaginated"

const useFetchAllEligibilityImportsPaginatedMutation = ({ user = {}, variables }) => {
  return useMutation(FETCH_ALL_ELIGIBILITY_IMPORTS_PAGINATED, {
    ...queryOptions(user, true),
    variables,
  })
}

export default useFetchAllEligibilityImportsPaginatedMutation
