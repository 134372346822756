import { useContext } from "react"
import { useLazyQuery } from "react-apollo"

import GET_COUNT_BY_STATUS from "queries/kitStatus/getCountByStatus"

import { SessionContext } from "contexts/session"

import { queryOptions } from "utils/helper"

import { useVariables, useSuperAdmin, useHasuraClaims } from "hooks"

const useStatusCount = (extraVariables = {}) => {
  const { user } = useContext(SessionContext)
  const {
    isHCPAdmin,
    rapidTestId,
    isHCPOrCanViewRapidTests,
  } = useHasuraClaims()
  const { isTargetUserHCPAdmin } = useSuperAdmin()

  const variables = {
    ...useVariables(),
    isHCPAdmin: isHCPAdmin || isTargetUserHCPAdmin,
    ...((isHCPAdmin || isTargetUserHCPAdmin) && {rapidTestId}),
    isHCPOrCanViewRapidTests: isHCPOrCanViewRapidTests || isTargetUserHCPAdmin,
    ...extraVariables,
  }

  return useLazyQuery(GET_COUNT_BY_STATUS(variables), {
    ...queryOptions(user),
    variables,
  })
}

export default useStatusCount
