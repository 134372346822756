import { gql } from "@apollo/client"

const FIND_ONE_GROUP = gql`
  query FindUserGroupByID($oktaId: String!) {
    groups: okta_groups(where: { okta_id: { _eq: $oktaId } }) {
      okta_id
      name
      role
      enterprise_client_ids
      enterprise_partner_id
      okta_group_user_memberships(where: { state: { _eq: "active" } }) {
        okta_user {
          okta_id
          email
          state
          firstName: first_name
          lastName: last_name
        }
      }
    }
  }
`;

export default FIND_ONE_GROUP;
