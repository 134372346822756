import { logError, logInfo } from 'utils/datadog'
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import { displayError } from 'components/molecules/common/popup/toast';
import { SessionProvider } from 'contexts/session';
import fetch from 'cross-fetch';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { ERROR_TYPE, ERROR_CONTENT } from 'utils/constants';

import cache from './cache';

const httpLink = createHttpLink({
  uri: process.env.GATSBY_HASURA_BASE_URL,
  fetch,
});

const handleKitRegistrationError = (graphQLErrors) => {
  let content = '';
  let header = ERROR_TYPE.KIT_REGISTRATION;
  if (graphQLErrors && graphQLErrors.length > 0) {
    const err = graphQLErrors[0];
    // Handle if the API returns `errors` key instead of `message`
    const inners = err?.extensions?.internal?.response?.body?.errors;
    if (inners && inners.length > 0) {
      content = inners.join(', ');
    } else {
      content = err?.message;
    }
  } else {
    content = ERROR_CONTENT.KIT_REGISTRATION;
  }
  // Handle specific eligibility error messages
  switch (content) {
    case 'Member not found':
      header = ERROR_TYPE.MEMBER_NOT_FOUND;
      content = ERROR_CONTENT.MEMBER_NOT_FOUND;
      break;
    case 'Eligible kit not found':
      header = ERROR_TYPE.MEMBER_NOT_ELIGIBLE;
      content = ERROR_CONTENT.MEMBER_NOT_ELIGIBLE;
      break;
    case 'No Order Number was provided':
      header = ERROR_TYPE.ORDER_NUMBER_MISSING;
      content = ERROR_CONTENT.MEMBER_NOT_FOUND;
      break;
    case 'No Member ID was provided':
      header = ERROR_TYPE.MEMBER_ID_MISSING;
      content = ERROR_CONTENT.MEMBER_NOT_FOUND;
      break;
    case 'Internal system error':
      header = ERROR_TYPE.INTERNAL_SYSTEM_ERROR;
      content = ERROR_CONTENT.SYSTEM;
      break;
  }
  return displayError(header, content);
};

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message }) => {
      // [GraphQL error]: Message: Could not verify JWT: JWTExpired, Location: undefined, Path: undefined
      if (message.includes('JWTExpired')) {
        // hard reloading to enforce re-authorization
        window.location.reload();
      } else {
        const { operationName } = operation;

        if (operationName === 'ValidateRegistrationAddress') {
          logInfo('Address validation error', {
            reason: message
          });
          return null;
        }

        let exception = new Error(`[GraphQL error]: Message: ${message}`);
        exception.name = operationName;



        logError('Error on QS dashboard embedding', {
          reason: message,
          error: exception
        });

        if (operationName === 'CreateAccessCode') {
          displayError(
            ERROR_TYPE.ACCESS_CODE,
            ERROR_CONTENT.CREATE_ACCESS_CODE,
          );
        } else if (operationName === 'UpdateAccessCode') {
          displayError(
            ERROR_TYPE.ACCESS_CODE,
            ERROR_CONTENT.UPDATE_ACCESS_CODE,
          );
        } else if (operationName === 'CreateKitRegistration') {
          handleKitRegistrationError(graphQLErrors);
        }
        else {
          displayError(ERROR_TYPE.NETWORK, ERROR_CONTENT.NETWORK);
        }
      }

      return null;
    });

  if (networkError) {
    let networkException = new Error(`[Network error]: ${networkError}`);
    networkException.name = 'Network error';

    logInfo('Network error', {
      reason: networkError,
      error: networkException
    });

    displayError(ERROR_TYPE.NETWORK, ERROR_CONTENT.NETWORK);
  }
});

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache,
});

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <SessionProvider>{element}</SessionProvider>
  </ApolloProvider>
);
