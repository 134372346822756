import gql from "graphql-tag"

const UPDATE_USER_GROUP = gql`
  mutation updateOktaUserGroupMembership(
    $userId: String!
    $previousGroupId: String!
    $targetGroupId: String!
    $canViewRapidTests: Boolean!
    $canRegisterKits: Boolean!
    $targetOktaGroupRole: String
  ) {
    updateOktaUserGroupMembership(
      okta_id: $userId
      previous_okta_group_id: $previousGroupId
      target_okta_group_id: $targetGroupId
      can_view_rapid_tests: $canViewRapidTests
      can_register_kits: $canRegisterKits
      target_okta_group_role: $targetOktaGroupRole
    ) {
      oktaId: okta_id
      status: user_status
      error
    }
  }
`;

export default UPDATE_USER_GROUP
