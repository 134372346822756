import { useQuery } from "react-apollo"

import GET_USER_COLUMN_PREFERENCE from 'queries/user/getOktaUser';

import { useHasuraClaims, useSuperAdmin } from 'hooks';
import { queryOptions } from 'utils/helper';

const useUserColumnPreference = (user = {}, targetUser = {}) => {
  const { currentUserId, isHCPAdmin } = useHasuraClaims();
  const { isMasqueradeMode } = useSuperAdmin();

  // TODO: handle masquerade mode
  const userId = isMasqueradeMode ? targetUser.okta_user_id : currentUserId;

  return useQuery(GET_USER_COLUMN_PREFERENCE, {
    ...queryOptions(user),
    variables: { oktaId: userId },
    skip: !userId || isHCPAdmin,
  });
};

export default useUserColumnPreference
