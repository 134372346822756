import { gql } from "@apollo/client"

const FIND_ALL_USER_IMPORT_RESULTS = gql`
  query Users($jobId: String!) {
    downloadUserImportErrors(job_id: $jobId) {
      last_name
      first_name
      email
      can_register_kits
      can_view_rapid_tests
      can_manage_access_codes
      error_msg
    }
  }
`;

export default FIND_ALL_USER_IMPORT_RESULTS
