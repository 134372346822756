import { useContext } from "react"
import { useMutation } from "react-apollo"

import { SessionContext } from "contexts/session"

import { queryOptions } from 'utils/helper';

import IMPORT_USERS from '../../graphql/UserManagement/mutations/importUser';

const useImportUserMutation = () => {
  const { user } = useContext(SessionContext);

  return useMutation(IMPORT_USERS, {
    ...queryOptions(user, true),
  });
};

export default useImportUserMutation
