import React from "react"
import { toast } from "react-toastify"

import Toast from "components/common/Toast"

export const displayError = (message, description) => {
  toast(<Toast message={message} description={description} type={"error"} />, {
    position: toast.POSITION.TOP_CENTER,
    type: "error",
    autoClose: 4000,
  })
}
