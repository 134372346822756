import { gql } from "@apollo/client"

const FIND_ALL_USERS = gql`
  query AllUsers {
    allUsers: okta_user_view {
      id: okta_id
      oktaId: okta_id
      email
      state
      firstName: first_name
      lastName: last_name
      groupName: group_name
      groupRole: group_role
      partnerId: enterprise_partner_id
      clientIds: enterprise_client_ids
    }
  }
`;

export default FIND_ALL_USERS
