import { useContext } from "react"
import { useLazyQuery } from "react-apollo"

import { SessionContext } from "contexts/session"
import { queryOptions } from 'utils/helper';

import FIND_ALL_USER_IMPORT_RESULTS from '../../queries/userManagement/findAllUserImportResults';

const useImportUserResults = (options) => {
  const { user } = useContext(SessionContext);

  return useLazyQuery(FIND_ALL_USER_IMPORT_RESULTS, {
    ...queryOptions(user),
    ...options,
  });
};

export default useImportUserResults
