import { datadogRum } from "@datadog/browser-rum"
import { datadogLogs } from "@datadog/browser-logs"

/**
 * Datadog Logging initialization method.
 */
export function initializeDatadog() {
  const ddConfig = {
    clientToken: process.env.GATSBY_DATADOG_CLIENT_TOKEN || "",
    site: "datadoghq.com",
    service: "lens",
    env: process.env.GATSBY_DATADOG_ENV,
    sessionSampleRate: 100,
    silentMultipleInit: true,
    tags: {
      kube_namespace: process.env.NAMESPACE || "",
    }
  }

  datadogRum.init({
    ...ddConfig,
    applicationId: process.env.GATSBY_DATADOG_APPLICATION_ID || "",
    sessionReplaySampleRate: 0,
    trackInteractions: true,
  })

  datadogLogs.init({
    ...ddConfig,
    forwardErrorsToLogs: true,
    telemetrySampleRate: 0,
  })
}

const updateDataDogUserContext = (session) => {
  const { user, targetUser, clients, partnerName } = session
  const context = datadogLogs.getGlobalContext()
  const user_email_match = user?.email === context?.details?.user?.email
  const target_user_email_match = targetUser?.email === context?.details?.targetUser?.email
  if (user_email_match && target_user_email_match) { return }
  const newContext = {
    ...context,
    details: {
      user: {
        name: user?.name,
        email: user?.email,
        group: user?.group,
      },
      targetUser: {
        role: targetUser?.role,
        email: targetUser?.email,
        enterprise_client_ids: targetUser?.enterprise_client_ids
      },
      client_ids: clients?.map(client => client.id),
      partnerName: partnerName
    },
    correlation_ids: {
      enterprise_partner_id: clients?.[0]?.enterprise_partner_id,
    }
  }
  datadogLogs.setGlobalContext(newContext);
}

const logError = (error, context) => {
  datadogLogs.logger.error(error, context)
}

const logInfo = (msg, context) => {
  datadogLogs.logger.info(msg, context)
}

export {
  updateDataDogUserContext,
  logError,
  logInfo
}