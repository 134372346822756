import { useContext } from "react"
import { useMutation } from "react-apollo"

import { SessionContext } from "contexts/session"

import { queryOptions } from "utils/helper"

import UPDATE_USER from "../../graphql/UserManagement/mutations/updateUser"

const useUpdateUserMutation = options => {
  const { user } = useContext(SessionContext)

  return useMutation(UPDATE_USER, {
    ...queryOptions(user, true),
    ...options,
    refetchQueries: ['FindOneUser'],
  });
}

export default useUpdateUserMutation
