import gql from 'graphql-tag';

const VALIDATE_REGISTRATION_ADDRESS = gql`
  mutation ValidateRegistrationAddress(
    $address1: String!,
    $address2: String,
    $city: String!,
    $state: String!,
    $zipcode: String!
  ) {
    validateRegistrationAddress(
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zipcode: $zipcode
    ) {
      message
      extensions {
        errors
        address {
          address1
          address2
          city
          state
          zipcode
        }
        address_suggestions {
          address1
          address2
          city
          state
          zipcode
        }
      }
    }
  }
`;

export default VALIDATE_REGISTRATION_ADDRESS;
