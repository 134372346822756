import { gql } from '@apollo/client';

const FIND_ALL_OKTA_GROUPS_IN_ADMIN_SCOPE = gql`
  query AllOktaGroupsInAdminScope($client_ids: [bigint], $partner_id: Int) {
    allUserGroups: okta_group_view(
      where: {
        _and: [
          { enterprise_partner_id: { _eq: $partner_id } }
          { client_ids: { _contained_in: $client_ids } }
        ]
      }
    ) {
      id: okta_id
      name
      role
      okta_id
      enterprise_partner_id
      enterprise_client_ids: client_ids_jsonb
      userCount: user_count
    }
  }
`;

export default FIND_ALL_OKTA_GROUPS_IN_ADMIN_SCOPE;
