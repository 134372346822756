import { useContext } from 'react';
import { useQuery } from 'react-apollo';

import { SessionContext } from 'contexts/session';
import { queryOptions } from 'utils/helper';

import FIND_ONE_USER from '../../queries/userManagement/findOneUser';

const useOneUser = ({ oktaId }) => {
  const { user } = useContext(SessionContext);

  return useQuery(FIND_ONE_USER, {
    ...queryOptions(user),
    variables: { oktaId },
    skip: !oktaId,
  });
};

export default useOneUser;
