import gql from "graphql-tag"

const DOWNLOAD_KIT_RESULTS = gql`
  mutation downloadKitResults(
    $userId: String!
    $kitResultIds: [Int!]
  ) {
    downloadKitResults(
      userId: $userId
      kitResultIds: $kitResultIds
    ) {
      message
    }
  }
`

export default DOWNLOAD_KIT_RESULTS
