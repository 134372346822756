import gql from "graphql-tag"

const ACTIVATE_USER = gql`
  mutation activateOktaUser($oktaId: String!) {
    activateOktaUser(okta_id: $oktaId) {
      okta_id
      status: user_status
      error
    }
  }
`

export default ACTIVATE_USER
