import { useContext } from "react"

import { SessionContext } from "contexts/session"

import { HASURA_ROLE } from "utils/constants"

const useHasuraClaims = () => {
  const { user } = useContext(SessionContext)

  const claims = user?.idToken?.claims || {};

  const xHasuraRole = claims['x-hasura-role'];
  const hasuraClaims = JSON.parse(claims?.hasura || '{}') || {};

  const isEverlywellSuperAdmin = xHasuraRole === HASURA_ROLE.superAdmin

  const isEnterprisePartnerClinicalAdmin = [
    HASURA_ROLE.partner,
    HASURA_ROLE.partner_hcp,
    HASURA_ROLE.partner_non_phi,
  ].includes(xHasuraRole)

  const isEnterpriseClientClinicalAdmin = [
    HASURA_ROLE.client,
    HASURA_ROLE.client_hcp,
    HASURA_ROLE.client_non_phi,
  ].includes(xHasuraRole)

  const isHCPAdmin = [
    HASURA_ROLE.partner_hcp,
    HASURA_ROLE.client_hcp
  ].includes(xHasuraRole)

  const isNonPhiAdmin = [
    HASURA_ROLE.partner_non_phi,
    HASURA_ROLE.client_non_phi,
  ].includes(xHasuraRole)

  const isEnterprisePartnerNonPhiAdmin = [HASURA_ROLE.partner_non_phi].includes(
    xHasuraRole
  )

  const isEnterpriseClientNonPhiAdmin = [HASURA_ROLE.client_non_phi].includes(
    xHasuraRole
  )

  const rapidTestId = parseInt(process.env.GATSBY_RAPID_TEST_ID)

  const canManageUsers = hasuraClaims["x-hasura-can-manage-users"] === "true"

  const canViewRapidTests =
    hasuraClaims["x-hasura-can-view-rapid-tests"] === "true"

  const isHCPOrCanViewRapidTests = isHCPAdmin || canViewRapidTests

  const hasAccessToAccessCodePages =
    hasuraClaims["x-hasura-access-code-manager"] === "true"

  const hasAccessToEligibilityFilesPage =
    hasuraClaims["x-hasura-can-manage-eligibility-files"] === "true"

  const hasAccessToAdministrationPages = hasAccessToAccessCodePages || hasAccessToEligibilityFilesPage

  const hasAccessToRegistrationPages =
    hasuraClaims["x-hasura-can-register-kits"] === "true"

  const enterprisePartnerId = parseInt(hasuraClaims["x-hasura-partner-id"])
  const enterpriseClientIds = hasuraClaims["x-hasura-client-ids"]?.slice(1, -1)
    ? hasuraClaims["x-hasura-client-ids"]
      .slice(1, -1)
      .split(",")
      .map(id => parseInt(id))
    : null

  const hasMoreThanOneClientId = enterpriseClientIds?.length > 1

  const hasAccessToMultipleClients =
    isEnterprisePartnerClinicalAdmin || hasMoreThanOneClientId

  const isEnterprisePartnerAdmin =
    isEnterprisePartnerClinicalAdmin || isEnterprisePartnerNonPhiAdmin

  const isEnterpriseClientAdmin =
    isEnterpriseClientClinicalAdmin || isEnterpriseClientNonPhiAdmin

  const isEnterpriseOneClientAdmin =
    isEnterpriseClientAdmin && !hasMoreThanOneClientId

  const isEnterpriseMultipleClientAdmin =
    isEnterpriseClientAdmin && hasMoreThanOneClientId

  const isEnterprisePartnerOrClientAdmin =
    isEnterprisePartnerAdmin || isEnterpriseClientAdmin

  const { name: currentUserName } = claims;
  const currentUserId = claims?.sub;

  return {
    isHasuraDefined: !!claims?.hasura,

    hasAccessToAccessCodePages,
    hasAccessToMultipleClients,
    hasAccessToRegistrationPages,
    hasAccessToEligibilityFilesPage,
    hasAccessToAdministrationPages,

    isEnterprisePartnerClinicalAdmin,
    isEnterpriseClientClinicalAdmin,

    isEnterprisePartnerNonPhiAdmin,
    isEnterpriseClientNonPhiAdmin,

    isEverlywellSuperAdmin,
    isEnterprisePartnerAdmin,
    isEnterpriseClientAdmin,
    isEnterpriseOneClientAdmin,
    isEnterpriseMultipleClientAdmin,
    isEnterprisePartnerOrClientAdmin,
    canManageUsers,

    isHCPAdmin,
    isNonPhiAdmin,
    rapidTestId,
    canViewRapidTests,
    isHCPOrCanViewRapidTests,

    enterprisePartnerId,
    enterpriseClientIds,

    currentUserName,
    currentUserId,

    userRole: xHasuraRole,
  };
}

export default useHasuraClaims
