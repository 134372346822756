import { useMutation } from "react-apollo"

import UPDATE_OKTA_USER_PREFERENCES from 'queries/user/updateOktaUserPreferences';

import { useHasuraClaims } from 'hooks';
import { queryOptions } from 'utils/helper';

const useUpdateUserColumnPreferenceMutation = ({ user = {}, columns = {} }) => {
  const { currentUserId } = useHasuraClaims();

  return useMutation(UPDATE_OKTA_USER_PREFERENCES, {
    ...queryOptions(user, true),
    variables: {
      okta_id: currentUserId,
      test_kit_table_config: columns,
    },
    fetchQueries: ['OktaUser'],
  });
};

export default useUpdateUserColumnPreferenceMutation
