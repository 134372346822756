import { useMutation } from 'react-apollo';
import { queryOptions } from 'utils/helper';
import { useContext } from 'react';
import { SessionContext } from 'contexts/session';

import EMBED_DASHBOARD_URL from '../graphql/Analytics/mutations/embedDashboardUrl';

const useEmbedUrl = (oktaUserId, enterprisePartnerId) => {
  const { user } = useContext(SessionContext);

  return useMutation(EMBED_DASHBOARD_URL, {
    ...queryOptions(user, true),
    variables: {
      oktaUserId,
      enterprisePartnerId,
    },
  });
};

export default useEmbedUrl;
