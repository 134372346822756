import { useState, useEffect, useContext } from "react"

import { SessionContext } from "contexts/session"
import {
  useSuperAdmin,
  useLazyClients,
  useLazyPartner,
  useHasuraClaims,
} from "hooks"

import analytics from "utils/analytics"
import { findPartnerName, findClientNames } from "utils/enterprisePartnerHelper"

const useEventTracking = () => {
  const {
    user,
    clientNames,
    partnerName,
    setClientNames,
    setPartnerName,
  } = useContext(SessionContext)

  const { enterprisePartnerId, enterpriseClientIds } = useHasuraClaims()
  const { isMasqueradeMode, isEverlywellSuperAdmin } = useSuperAdmin()

  const [isTrackingReady, setIsTrackingReady] = useState(
    partnerName && clientNames
  )

  const handleClients = data => {
    if (data?.enterprise_clients) setClientNames(findClientNames(data))
  }

  const handlePartner = data => {
    if (data?.enterprise_partners) setPartnerName(findPartnerName(data)[0])
  }

  const [getClientNames, { called: isClientCalled }] = useLazyClients(
    user,
    handleClients
  )

  const [getPartnerName, { called: isPartnerCalled }] = useLazyPartner(
    user,
    { id: enterprisePartnerId },
    isEverlywellSuperAdmin && !isMasqueradeMode,
    handlePartner
  )

  useEffect(() => {
    if (isTrackingReady) return

    getPartnerName()
    if (enterpriseClientIds) getClientNames()
  }, [])

  useEffect(() => {
    if (isTrackingReady) return
    if (!isPartnerCalled || (enterpriseClientIds && !isClientCalled)) return
    if (partnerName && clientNames) setIsTrackingReady(true)
  }, [
    partnerName,
    clientNames,
    isClientCalled,
    isPartnerCalled,
    isTrackingReady,
    enterpriseClientIds,
  ])

  const trackEvent = (eventName, data, isSuperAdminTrackable = false) => {
    if (!isTrackingReady) return
    if (isMasqueradeMode || (isEverlywellSuperAdmin && !isSuperAdminTrackable))
      return

    if (!isEverlywellSuperAdmin) {
      data = {
        ...data,
        partner_name: partnerName,
        client_names: clientNames,
      }
    }

    analytics.track(eventName, data)
  }

  return {
    trackEvent,
    isTrackingReady,
  }
}

export default useEventTracking
