import { gql } from '@apollo/client';

const FIND_ALL_OKTA_USERS_IN_ADMIN_SCOPE = gql`
  query AllOktaUsersInAdminScope($client_ids: [bigint], $partner_id: Int) {
    allUsers: okta_user_view(
      where: {
        _and: [
          { enterprise_partner_id: { _eq: $partner_id } }
          { enterprise_client_ids_array: { _contained_in: $client_ids } }
        ]
      }
    ) {
      id: okta_id
      oktaId: okta_id
      email
      state
      firstName: first_name
      lastName: last_name
      groupName: group_name
      groupRole: group_role
      partnerId: enterprise_partner_id
      clientIds: enterprise_client_ids
    }
  }
`;

export default FIND_ALL_OKTA_USERS_IN_ADMIN_SCOPE;
