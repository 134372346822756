import { useMutation } from "react-apollo"

import { queryOptions } from "utils/helper"

import VALIDATE_REGISTRATION_ADDRESS from "../graphql/KitRegistration/mutations/validateRegistrationAddress"

const useValidateRegistrationAddressMutation = ({ user = {}, variables }) => {
  return useMutation(VALIDATE_REGISTRATION_ADDRESS, {
    ...queryOptions(user, true),
    variables
  })
}

export default useValidateRegistrationAddressMutation
