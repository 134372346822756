import gql from "graphql-tag"

const RESET_USER_PASSWORD = gql`
  mutation resetOktaUserPassword($oktaId: String!) {
    resetOktaUserPassword(okta_id: $oktaId) {
      okta_id
      status: user_status
      error
    }
  }
`

export default RESET_USER_PASSWORD
