import { useContext } from "react"
import { useMutation } from "react-apollo"

import { SessionContext } from "contexts/session"

import { queryOptions } from "utils/helper"

import RESET_USER_PASSWORD from "../../graphql/UserManagement/mutations/resetUserPassword"

const useResetUserPasswordMutation = options => {
  const { user } = useContext(SessionContext)

  return useMutation(RESET_USER_PASSWORD, {
    ...queryOptions(user, true),
    ...options,
    refetchQueries: ['FindOneUser'],
  });
}

export default useResetUserPasswordMutation
