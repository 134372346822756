import { useLazyQuery } from "react-apollo"
import { queryOptions } from "utils/helper"

import GET_ENTERPRISE_PARTNER_NAME from "../queries/settings/userProfile/get-company"

const useLazyPartner = (
  user = {},
  variables = {},
  skip,
  handleCompleted,
  handleError
) => {
  return useLazyQuery(GET_ENTERPRISE_PARTNER_NAME, {
    ...queryOptions(user),
    variables,
    skip,
    onCompleted: handleCompleted,
    onError: handleError,
  })
}

export default useLazyPartner
