import dayjs from "dayjs"
import { FULFILLMENT_PROVIDERS, HASURA_ROLES_DICTIONARY, US_STATES } from './constants';

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const formatKitID = (kitId, provider) => {
  if (FULFILLMENT_PROVIDERS[provider]) {
    return FULFILLMENT_PROVIDERS[provider].formatKitId(kitId);
  } else {
    // default
    return kitId.replace(/(.{3})(.{3})(.{4})/g, '$1-$2-$3');
  }
};

const queryOptions = (user, isMutation = false) => {
  const idTokenInst = user.idToken.idToken;

  const claims = user?.idToken?.claims || {};
  const role = claims['x-hasura-role'];

  return {
    context: {
      headers: {
        authorization: idTokenInst ? `Bearer ${idTokenInst}` : '',
        'x-hasura-role': role,
      },
    },
    fetchPolicy: isMutation ? 'no-cache' : 'cache-and-network',
  };
};

const gqlQueryVariables = (defined, passed = null) => {
  const query_variable_keys = Object.keys(defined);
  const definition = query_variable_keys
    .map((k) => `$${k}: ${defined[k]}`)
    .join('\n');
  let filtered;
  if (passed) {
    filtered = query_variable_keys.reduce((acc, key) => {
      acc[key] = passed[key];
      return acc;
    }, {});
  }
  return {
    definition,
    filtered,
  };
};

// searching for full name
const getLastNameSearchText = (preSearchText) => {
  if (preSearchText.split(' ').length > 1) {
    return `%${preSearchText.split(' ')[1]}%`;
  }
  return `%${preSearchText.replace(/[-]/g, '')}%`;
};

const truncate = (text, limit = 21) => {
  if (!text) {
    return '';
  }

  if (text.length <= limit) {
    return text;
  }

  const truncatedText = text?.substring(0, limit - 3)?.trim();

  return `${truncatedText}...`;
};

// get client ID array from hasura claim
const getHasuraClientIDs = (hasuraClaims) => {
  const clientIds = hasuraClaims['x-hasura-client-ids'];
  if (clientIds) {
    return clientIds
      .replace(/[{}]/, '')
      .split(',')
      .map((e) => parseInt(e));
  }
  return null;
};

const isValidDate = (date) => {
  return date && date.getTime && !isNaN(date.getTime());
};

const capitalize = (s) => {
  if (typeof s !== 'string') return '';

  const text = s?.toLowerCase()?.split('_')?.join(' ');

  return text.charAt(0).toUpperCase() + text.slice(1);
};

const getLabelFromColumn = (column) => {
  if (column === 'collected') {
    return 'Sample Collected';
  } else if (column === 'enterprise_program_name') {
    return 'Program Name';
  }

  return column
    .split('_')
    .map((word) => {
      let formattedWord;

      if (word === 'id') {
        formattedWord = 'ID';
      } else if (['by', 'at'].includes(word)) {
        formattedWord = word;
      } else {
        formattedWord = capitalize(word);
      }

      return formattedWord;
    })
    .join(' ');
};

const getThirdPartyIdConfig = (partnerConfig) => {
  const [
    label,
    regex_format,
    third_party_id_registration_enabled,
    user_email_required,
  ] = partnerConfig
    ? [
        partnerConfig.third_party_id?.label || 'Third Party Member ID',
        partnerConfig.third_party_id?.regex_format,
        partnerConfig.third_party_id_registration_enabled,
        partnerConfig.user_email_required,
      ]
    : ['Third Party Member ID', '', false, true];

  return {
    label,
    regex_format,
    third_party_id_registration_enabled,
    user_email_required,
  };
};

const isValidId = (value, format) => {
  if (value) {
    const reg = new RegExp(format, 'i'); // Case-insensitive
    return reg.test(value);
  }
  return false;
};

const isValidEmail = (value, isRequired) => {
  if (!isRequired && !value) {
    return true;
  }

  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
};

/**
 * Normalizes the phone number by the format '123-456-7890'
 *
 * @param {string} input the current typed phone
 */
const normalizePhone = (input) => {
  if (!input) {
    return input;
  }

  const cur = input.replace(/[^\d]/g, '');
  const curLen = cur.length;

  if (curLen < 4) {
    return cur;
  }

  if (curLen < 7) {
    return `${cur.slice(0, 3)}-${cur.slice(3)}`;
  }

  return `${cur.slice(0, 3)}-${cur.slice(3, 6)}-${cur.slice(6, 10)}`;
};

const isValidPhone = (value) => {
  if (value && value.length > 0 && value[0] === '1') {
    return false;
  }
  return /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value);
};

const translateRole = (role) => {
  if (HASURA_ROLES_DICTIONARY[role]) return HASURA_ROLES_DICTIONARY[role];
  return HASURA_ROLES_DICTIONARY.enterprise_partner_admin;
};

const isValidZipCode = (value) => {
  return /^\d+$/.test(value) && value.toString().length === 5;
};

const isValidAddress1 = (value) => {
  return value.length > 1 && isValidAddressString(value);
};

const isValidAddressString = (value) => {
  const regex = /[¿?¡!<>=+%{}[\]\\^*$|¨]/g;
  return !regex.test(value);
};

const translateStateAbbrv = (state) => {
  return US_STATES.find((item) => item.value === state)?.text;
}

export {
  formatKitID,
  queryOptions,
  gqlQueryVariables,
  getLastNameSearchText,
  truncate,
  getHasuraClientIDs,
  isValidDate,
  getLabelFromColumn,
  getThirdPartyIdConfig,
  isValidId,
  isValidEmail,
  normalizePhone,
  isValidPhone,
  capitalize,
  translateRole,
  isValidZipCode,
  isValidAddress1,
  isValidAddressString,
  translateStateAbbrv
};
