import { useContext } from 'react';
import { useQuery } from 'react-apollo';

import { queryOptions } from 'utils/helper';
import { SessionContext } from 'contexts/session';

import GET_TARGET_USER_DETAILS from '../queries/superAdmin/getTargetUserDetails';

const useTargetUserDetails = (oktaId, options = {}) => {
  const { user } = useContext(SessionContext);

  return useQuery(GET_TARGET_USER_DETAILS, {
    ...queryOptions(user),
    variables: { oktaId },
    skip: !oktaId,
    ...options,
  });
};

export default useTargetUserDetails;
