import { gql } from "@apollo/client"

const FIND_ONE_USER_BY_EMAIL = gql`
  query FindUserByEmail($email: String!) {
    getUserByEmail: okta_users(where: { email: { _ilike: $email } }) {
      id: okta_id
      email
    }
  }
`;

export default FIND_ONE_USER_BY_EMAIL
