import { useContext } from 'react';
import { useQuery } from 'react-apollo';

import { queryOptions } from 'utils/helper';
import { SessionContext } from 'contexts/session';

import GET_USER_OKTA_ID_BY_EMAIL from '../queries/superAdmin/getUserOktaIdByEmail';

const useOktaUserByEmail = (email, options = {}) => {
  const { user } = useContext(SessionContext);

  return useQuery(GET_USER_OKTA_ID_BY_EMAIL, {
    ...queryOptions(user),
    variables: { email },
    skip: !email,
    ...options,
  });
};

export default useOktaUserByEmail;
