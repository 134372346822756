import { gql } from '@apollo/client';

const FIND_ALL_OKTA_GROUPS = gql`
  query FindAllGroups {
    allUserGroups: okta_group_view {
      id: okta_id
      name
      role
      okta_id
      enterprise_partner_id
      enterprise_client_ids: client_ids_jsonb
      userCount: user_count
    }
  }
`;

export default FIND_ALL_OKTA_GROUPS;
