import gql from "graphql-tag"

const REACTIVATE_USER = gql`
  mutation reactivateOktaUser($oktaId: String!) {
    reactivateOktaUser(okta_id: $oktaId) {
      okta_id
      status: user_status
      error
    }
  }
`

export default REACTIVATE_USER
