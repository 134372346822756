
import { AccessCodeWizardContext } from 'contexts/accessCodeWizard'
import { SessionContext } from "contexts/session"
import {
  useSuperAdmin,
  useHasuraClaims,
  usePartnerProducts,
  usePartnerConfig,
  useClients,
} from "hooks"
import { useContext, useEffect, useMemo } from "react"
import { HASURA_ROLE } from "utils/constants"

import usePaidAccessCodeStatus from "./usePaidAccessCodeEnabled"

const getClientsList = (data) => {
  return data?.enterprise_clients
    ?.map(client => ({ ...client, value: client.name }))
    ?.sort((a, b) => a.value.localeCompare(b.value)) || []
}

const useAccessCodeWizard = () => {
  const { state, dispatch } = useContext(AccessCodeWizardContext)
  const { user } = useContext(SessionContext) || {}

  const { data } = useClients(user)
  const enterpriseClients = useMemo(() => getClientsList(data), [data])
  const shouldDisableFields = state?.mode === 'update' && state?.hasOrders

  const {
    hasAccessToMultipleClients,
    isEnterpriseClientClinicalAdmin,
    isEnterprisePartnerClinicalAdmin,
    enterprisePartnerId,
    userRole,
  } = useHasuraClaims()

  const {
    isEverlywellSuperAdmin,
    hasTargetAccessToMultipleClients,
    isEverlywellSuperAdminClient,
    targetRole
  } = useSuperAdmin()

  const { paidAccessCodesEnabled, handlePaidAccessCodesChange } = usePaidAccessCodeStatus()

  const isClientAdmin = isEnterpriseClientClinicalAdmin || isEverlywellSuperAdminClient
  const isPartnerAdmin =
    isEnterprisePartnerClinicalAdmin ||
    (isEverlywellSuperAdmin && targetRole === HASURA_ROLE.partner)

  const hasMultipleClients =
    hasAccessToMultipleClients ||
    (isEverlywellSuperAdmin && hasTargetAccessToMultipleClients)

  const { data: products } = usePartnerProducts(user)

  const { data: enterprisePartnerConfig } = usePartnerConfig(user, {
    partner_id: enterprisePartnerId,
  })

  const enterprisePartnerProducts = useMemo(() => {
    return products?.enterprise_partner_products?.map(product => ({
      ...product,
      name: product?.spree_product?.name,
    })) || []
  }, [products])

  const hasAutoRegEnabled = (clientId) => {
    const client = enterpriseClients.find(ec => ec?.id === Number(clientId))

    if (!client && !enterprisePartnerConfig) return false

    if (!client && enterprisePartnerConfig) {
      return enterprisePartnerConfig
        ?.enterprise_partner_configs[0]
        ?.auto_registration_enabled
    }

    return client?.enterprise_partner_configs[0]?.auto_registration_enabled
  }

  useEffect(() => {
    if (!isClientAdmin || hasMultipleClients) return

    handlePaidAccessCodesChange(enterpriseClients[0])
  }, [isClientAdmin, hasMultipleClients, enterpriseClients[0]])

  return {
    state,
    dispatch,
    hasMultipleClients,
    isClientAdmin,
    enterprisePartnerId,
    paidAccessCodesEnabled,
    handlePaidAccessCodesChange,
    enterpriseClients,
    enterprisePartnerProducts,
    isPartnerAdmin,
    userRole,
    shouldDisableFields,
    hasAutoRegEnabled
  }
}

export default useAccessCodeWizard
