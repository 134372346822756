import { useLazyQuery } from "react-apollo"

import { queryOptions } from "utils/helper"

import GET_KIT_REGISTRATIONS from "../queries/kitRegistrations/getKitRegistrations"

const useKitRegistrations = (user = {}, id) => {
  return useLazyQuery(GET_KIT_REGISTRATIONS, {
    ...queryOptions(user),
    variables: { id },
  })
}

export default useKitRegistrations
