import { gql } from "@apollo/client"

const getNamesWhereClause = ({ }) => "enterprise_program_name: { _in: $names }"

const getProgramNameCount = (vars) =>
  vars.partner_id || vars.client_ids
    ? gql`
    query ProgramNameCount($partner_id: Int, $client_ids: [Int!], $names: [String!]) {
      programNames: program_names_view(where: { ${getNamesWhereClause(vars)} }, distinct_on: enterprise_program_name) {
        enterprise_program_name
        kit_results_aggregate(where: {
          ${
            vars?.partner_id
              ? `enterprise_partner_id:{_eq:$partner_id}`
              : ""
          }
          ${
            vars?.client_ids ? `enterprise_client_id:{_in:$client_ids}` : ""
          }
        }) {
          aggregate {
            count
          }
        }
      }
    }
  `
  : gql`
    query ProgramNameCount($names: [String!]) {
      programNames: program_names_view(where: { ${getNamesWhereClause(vars)} }, distinct_on: enterprise_program_name) {
        enterprise_program_name
        kit_results_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  `

const GET_PROGRAM_NAME_COUNT = vars => getProgramNameCount(vars)

export default GET_PROGRAM_NAME_COUNT
