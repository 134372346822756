import { gql } from '@apollo/client';

const UPDATE_OKTA_USER_PREFERENCES = gql`
  mutation UpdateOktaUserPreferences(
    $okta_id: String!
    $send_email_digest: Boolean
    $test_kit_table_config: jsonb
  ) {
    updateOktaUserPreferences(
      okta_id: $okta_id
      send_email_digest: $send_email_digest
      test_kit_table_config: $test_kit_table_config
    ) {
      id
    }
  }
`;

export default UPDATE_OKTA_USER_PREFERENCES;
