import gql from 'graphql-tag';

const EMBED_DASHBOARD_URL = gql`
  mutation embedDashboardUrl($oktaUserId: String!, $enterprisePartnerId: Int!) {
    embedDashboardUrl(
      okta_user_id: $oktaUserId
      enterprise_partner_id: $enterprisePartnerId
    ) {
      embed_url
    }
  }
`;

export default EMBED_DASHBOARD_URL;
