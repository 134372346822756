import gql from "graphql-tag"

const UPDATE_USER = gql`
  mutation updateOktaUser(
    $id: String!
    $email: String
    $firstName: String
    $lastName: String
    $canManageAccessCodes: Boolean
    $canRegisterKits: Boolean
    $canViewRapidTests: Boolean
    $canManageUsers: Boolean
  ) {
    updateOktaUser(
      okta_id: $id
      email: $email
      first_name: $firstName
      last_name: $lastName
      can_manage_access_codes: $canManageAccessCodes
      can_register_kits: $canRegisterKits
      can_view_rapid_tests: $canViewRapidTests
      can_manage_users: $canManageUsers
    ) {
      oktaId: okta_id
      status: user_status
      error
    }
  }
`

export default UPDATE_USER
