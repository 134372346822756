import { useContext } from "react"
import { useMutation } from "react-apollo"

import { SessionContext } from "contexts/session"

import { queryOptions } from "utils/helper"
import DOWNLOAD_KIT_RESULTS from "../graphql/KitResultDownload/mutations/downloadKitResults"

const useDownloadKitResultsMutation = ({ ...options }) => {
  const { user } = useContext(SessionContext)

  return useMutation(DOWNLOAD_KIT_RESULTS, {
    ...queryOptions(user, true),
    ...options,
  })
}

export default useDownloadKitResultsMutation
