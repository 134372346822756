import { gql } from "@apollo/client"

const GET_KIT_REGISTRATIONS = gql`
  query($id: Int!) {
    kit_registrations(where: { id: { _eq: $id } }) {
      barcode {
        kit_result {
          id
        }
      }
    }
  }
`

export default GET_KIT_REGISTRATIONS
