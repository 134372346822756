import gql from "graphql-tag"

const START_ELIGIBILITY_IMPORT = gql`
  mutation startEligibilityImport(
    $uuid: String!
    $tenant: String!
  ) {
    startEligibilityImport(
      uuid: $uuid
      tenant: $tenant
    ) {
      status
    }
  }
`

export default START_ELIGIBILITY_IMPORT
