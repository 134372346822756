import gql from "graphql-tag"

const UNSUSPEND_USER = gql`
  mutation unsuspendOktaUser($oktaId: String!) {
    unsuspendOktaUser(okta_id: $oktaId) {
      okta_id
      status: user_status
      error
    }
  }
`

export default UNSUSPEND_USER
