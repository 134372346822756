import gql from "graphql-tag"

const DEACTIVATE_USER = gql`
  mutation deactivateOktaUser($oktaId: String!) {
    deactivateOktaUser(okta_id: $oktaId) {
      okta_id
      status: user_status
      error
    }
  }
`

export default DEACTIVATE_USER
