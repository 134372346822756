import { gql } from '@apollo/client';

const GET_OKTA_USER = gql`
  query OktaUser($oktaId: String!) {
    oktaUser: okta_users(where: { okta_id: { _eq: $oktaId } }) {
      id
      send_email_digest
      test_kit_table_config
    }
  }
`;

export default GET_OKTA_USER;
