import gql from 'graphql-tag';

const IMPORT_USERS = gql`
  mutation UserImport(
    $user_import_records: [OktaUserImportRecord] = {
      email: ""
      first_name: ""
      last_name: ""
      target_okta_group_id: ""
    }
    $okta_user_id: String!
    $okta_group_id: String!
    $okta_group_name: String!
  ) {
    importOktaUsers(
      okta_group_id: $okta_group_id
      okta_group_name: $okta_group_name
      okta_user_id: $okta_user_id
      user_import_records: $user_import_records
    ) {
      record_num
    }
  }
`;

export default IMPORT_USERS;
