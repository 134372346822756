import { useLazyQuery } from "react-apollo"

import { useHasuraClaims, useSuperAdmin, useVariables } from "../hooks"
import { queryOptions } from "utils/helper"

import GET_CLIENTS_BY_PARTNER_ID from "../queries/admin/getClientsByPartnerId"
import GET_CLIENTS_BY_CLIENT_IDS from "../queries/admin/getClientsByClientIds"

const useLazyClients = (
  user = {},
  handleCompleted,
  handleError,
  forceFindByClientIDs = false
) => {
  const {
    isEnterprisePartnerClinicalAdmin,
    isEnterprisePartnerNonPhiAdmin,
    isEnterpriseClientClinicalAdmin,
    isEnterpriseClientNonPhiAdmin,
    enterprisePartnerId,
    enterpriseClientIds,
  } = useHasuraClaims()

  const {
    isEverlywellSuperAdmin,
    targetPartnerId,
    targetClientIds,
  } = useSuperAdmin()

  const GET_CLIENTS = forceFindByClientIDs
    ? GET_CLIENTS_BY_CLIENT_IDS
    : ((isEnterprisePartnerClinicalAdmin || isEnterprisePartnerNonPhiAdmin) &&
        enterprisePartnerId) ||
      (isEverlywellSuperAdmin && targetPartnerId)
    ? GET_CLIENTS_BY_PARTNER_ID
    : ((isEnterpriseClientClinicalAdmin || isEnterpriseClientNonPhiAdmin) &&
        enterpriseClientIds) ||
      (isEverlywellSuperAdmin && targetClientIds)
    ? GET_CLIENTS_BY_CLIENT_IDS
    : GET_CLIENTS_BY_PARTNER_ID

  const variables = useVariables()

  return useLazyQuery(GET_CLIENTS, {
    ...queryOptions(user),
    variables,
    onCompleted: handleCompleted,
    onError: handleError,
  })
}

export default useLazyClients
