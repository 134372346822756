import gql from "graphql-tag"

const FETCH_ELIGIBILITY_IMPORT = gql`
  mutation fetchEligibilityImport(
    $uuid: String!
    $tenant: String!
  ) {
    fetchEligibilityImport(
      uuid: $uuid
      tenant: $tenant
    ) {
      status
      errors {
        error_count
        error_file_key
        error_download_link
        warning_file_key
        warning_download_link
      }
    }
  }
`

export default FETCH_ELIGIBILITY_IMPORT
