import { useContext } from "react"
import { useQuery } from "react-apollo"

import { SessionContext } from "contexts/session"
import { queryOptions } from 'utils/helper';

import FIND_ONE_GROUP from "../../queries/userManagement/findOneGroup"

const useOneGroup = ({ oktaId }) => {
  const { user } = useContext(SessionContext);

  return useQuery(FIND_ONE_GROUP, {
    ...queryOptions(user),
    variables: { oktaId },
    skip: !oktaId,
  });
};

export default useOneGroup
