import { graphql, useStaticQuery } from 'gatsby';

// temporary workaround on data fetch
const useFindContentfulProducts = ({ productIds }) => {
  const data = useStaticQuery(graphql`
    query {
      products: allContentfulProduct(filter: { productId: { nin: [null] } }) {
        nodes {
          id
          productId

          shortDescription

          boxImage {
            url
            size
          }
        }
      }
    }
  `);

  return (
    data?.products?.nodes?.filter(({ productId }) => {
      return productIds.includes(productId)
    })
  )
};

export default useFindContentfulProducts;
