import { useContext } from "react"
import { useMutation } from "react-apollo"

import { SessionContext } from "contexts/session"

import { queryOptions } from "utils/helper"

import UNSUSPEND_USER from "../../graphql/UserManagement/mutations/unsuspendUser"

const useUnsuspendUserMutation = options => {
  const { user } = useContext(SessionContext)

  return useMutation(UNSUSPEND_USER, {
    ...queryOptions(user, true),
    ...options,
    refetchQueries: ['FindOneUser'],
  });
}

export default useUnsuspendUserMutation
