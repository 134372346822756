import { gql } from "@apollo/client"

const FIND_ALL_USER_LOGS = gql`
  query UserLogs($actorId: String!, $size: Int) {
    okta_event_logs(
      where: { actor_id: { _eq: $actorId } }
      order_by: { timestamp: desc }
      limit: $size
    ) {
      id
      uuid
      actorId: actor_id
      actorName: actor_name
      actorType: actor_type
      event_name
      targets
      time: timestamp
    }
  }
`;

export default FIND_ALL_USER_LOGS
