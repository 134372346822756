import gql from "graphql-tag"

const CREATE_USER = gql`
  mutation createOktaUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $groupId: String!
    $canManageAccessCodes: Boolean!
    $canRegisterKits: Boolean!
    $canViewRapidTests: Boolean!
    $canManageUsers: Boolean
  ) {
    createOktaUser(
      email: $email
      first_name: $firstName
      last_name: $lastName
      group_id: $groupId
      can_manage_access_codes: $canManageAccessCodes
      can_register_kits: $canRegisterKits
      can_view_rapid_tests: $canViewRapidTests
      can_manage_users: $canManageUsers
    ) {
      oktaId: okta_id
      status: user_status
      error
    }
  }
`

export default CREATE_USER
