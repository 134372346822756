import { gql } from '@apollo/client';

const GET_USER_OKTA_ID_BY_EMAIL = gql`
  query GetUserOktaIdByEmail($email: String!) {
    okta_users(where: { email: { _eq: $email } }) {
      id
      okta_id
    }
  }
`;

export default GET_USER_OKTA_ID_BY_EMAIL;
