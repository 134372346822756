import React, { createContext, useReducer } from 'react'
import {
  getActiveTimezones,
  getDefaultTimezone,
  getTimezoneOffset,
} from "utils/datetime"

const AccessCodeWizardContext = createContext();
const { Provider } = AccessCodeWizardContext;

const initialState = {
  active: true,
  autoRegistration: false,
  client: '',
  code: '',
  name: '',
  startDate: new Date(),
  endDate: null,
  timezone: !!getActiveTimezones()[getTimezoneOffset()]
    ? getTimezoneOffset()
    : getDefaultTimezone(),
  deliveryOptions: ['7'],
  paymentOption: 'enterprise_client_paid',
  tests: [],
  accessCodeProducts: [],
  contentfulTestsData: [],
  hasOrders: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateAccessCodeData':
      return { ...state, ...action.data }
    default:
      throw new Error();
  }
};

const AccessCodeWizardProvider = ({ children, accessCode = {}, mode = 'create' }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...accessCode, mode });
  const value = { state, dispatch };

  return <Provider value={value}>{children}</Provider>;
}

export { AccessCodeWizardContext, AccessCodeWizardProvider }
