import gql from "graphql-tag"

const RESET_MULTIFACTOR = gql`
  mutation resetOktaUserMFA($oktaId: String!) {
    resetOktaUserMFA(okta_id: $oktaId) {
      okta_id
      status: user_status
      error
    }
  }
`

export default RESET_MULTIFACTOR
