import gql from "graphql-tag"

const SUSPEND_USER = gql`
  mutation suspendOktaUser($oktaId: String!) {
    suspendOktaUser(okta_id: $oktaId) {
      okta_id
      status: user_status
      error
    }
  }
`

export default SUSPEND_USER
