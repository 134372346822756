import { useContext } from "react"

import { SessionContext } from "contexts/session"

import { HASURA_ROLE } from "utils/constants"

const useSuperAdmin = () => {
  const { user, targetUser } = useContext(SessionContext)

  const claims = user?.idToken?.claims || {}
  const hasuraClaims = JSON.parse(claims?.hasura || "{}") || {}

  const role = claims["x-hasura-role"]
  const hasAccessToAccessCodePages =
    hasuraClaims["x-hasura-access-code-manager"] === "true"
  const hasAccessToRegistrationPages =
    hasuraClaims["x-hasura-can-register-kits"] === "true"
  const hasAccessToEligibilityFilesPage =
    hasuraClaims["x-hasura-can-manage-eligibility-files"] === "true"
  const partnerId = hasuraClaims["x-hasura-partner-id"]
  const clientIds = hasuraClaims["x-hasura-client-ids"]?.slice(1, -1)
    ? hasuraClaims["x-hasura-client-ids"].slice(1, -1).split(",")
    : null

  const isEverlywellSuperAdmin = role === HASURA_ROLE.superAdmin
  const isMasqueradeMode = !!targetUser?.email && isEverlywellSuperAdmin

  const isCurrentUserHCPAdmin = [
    HASURA_ROLE.partner_hcp,
    HASURA_ROLE.client_hcp,
  ].includes(role)

  const isTargetUserHCPAdmin =
    isMasqueradeMode &&
    [HASURA_ROLE.partner_hcp, HASURA_ROLE.client_hcp].includes(targetUser?.role)

  const isCurrentUserPartnerAdmin = [
    HASURA_ROLE.partner,
    HASURA_ROLE.partner_hcp,
    HASURA_ROLE.partner_non_phi,
  ].includes(role)

  const isTargetUserPartnerAdmin = [
    HASURA_ROLE.partner,
    HASURA_ROLE.partner_hcp,
    HASURA_ROLE.partner_non_phi,
  ].includes(targetUser?.role)

  const isCurrentUserClientAdmin = [
    HASURA_ROLE.client,
    HASURA_ROLE.client_hcp,
    HASURA_ROLE.client_non_phi,
  ].includes(role)

  const isTargetUserClientAdmin = [
    HASURA_ROLE.client,
    HASURA_ROLE.client_hcp,
    HASURA_ROLE.client_non_phi,
  ].includes(targetUser?.role)

  const isCurrentUserNonPhiAdmin = [
    HASURA_ROLE.partner_non_phi,
    HASURA_ROLE.client_non_phi,
  ].includes(role)

  const isTargetUserNonPhiAdmin = [
    HASURA_ROLE.partner_non_phi,
    HASURA_ROLE.client_non_phi,
  ].includes(targetUser?.role)

  let targetPartnerId = null
  if (isMasqueradeMode) {
    if (isTargetUserPartnerAdmin) {
      targetPartnerId = parseInt(targetUser?.enterprise_partner_id)
    }
  } else {
    if (isCurrentUserPartnerAdmin) {
      targetPartnerId = parseInt(partnerId)
    }
  }

  let targetClientIds = null
  if (isMasqueradeMode) {
    if (isTargetUserClientAdmin) {
      targetClientIds = targetUser?.enterprise_client_ids?.map(id =>
        parseInt(id)
      )
    }
  } else {
    if (isCurrentUserClientAdmin) {
      targetClientIds = clientIds?.map(id => parseInt(id))
    }
  }

  const targetUserPartnerId = parseInt(targetUser?.enterprise_partner_id)
  const targetUserClientIds = targetUser?.enterprise_client_ids?.map(id =>
    parseInt(id)
  )

  const hasTargetMoreThanOneClientId =
    targetUser?.enterprise_client_ids?.length > 1

  const isTargetOneClientAdmin =
    isTargetUserClientAdmin && !hasTargetMoreThanOneClientId

  const isTargetMultipleClientAdmin =
    isTargetUserClientAdmin && hasTargetMoreThanOneClientId

  const isTargetPartnerOrClientAdmin =
    isTargetUserPartnerAdmin || isTargetUserClientAdmin

  const canManageUsers = isMasqueradeMode
    ? !!targetUser?.can_manage_users
    : hasuraClaims['x-hasura-can-manage-users'] === 'true';

  const hasTargetAccessToAccessCodePages = isMasqueradeMode
    ? !!targetUser?.access_code_manager
    : hasAccessToAccessCodePages;

  const hasTargetAccessToEligibilityFilesPage = isMasqueradeMode
    ? !!targetUser?.can_manage_eligibility_files
    : hasAccessToEligibilityFilesPage;

  const hasTargetAccessToAdministrationPages = hasTargetAccessToAccessCodePages || hasTargetAccessToEligibilityFilesPage

  const hasTargetAccessToRegistrationPages = isMasqueradeMode
    ? !!targetUser?.can_register_kits
    : hasAccessToRegistrationPages;

  let targetRole = null
  if (isMasqueradeMode) {
    targetRole = targetUser.role
  } else {
    targetRole = isEverlywellSuperAdmin ? null : role
  }

  const hasTargetAccessToMultipleClients =
    targetRole === HASURA_ROLE.partner || targetClientIds?.length > 1

  const targetUserOktaUserId = targetUser?.okta_user_id

  const isEverlywellSuperAdminClient = isEverlywellSuperAdmin && targetRole === HASURA_ROLE.client
  const isEverlywellSuperAdminPartner = isEverlywellSuperAdmin && targetRole === HASURA_ROLE.partner

  const isNonPhiAdmin = isCurrentUserNonPhiAdmin || isTargetUserNonPhiAdmin

  return {
    isEverlywellSuperAdmin,
    isMasqueradeMode,
    targetPartnerId,
    targetClientIds,
    targetRole,
    targetUserOktaUserId,
    isTargetUserHCPAdmin,
    isTargetOneClientAdmin,
    isTargetMultipleClientAdmin,
    isTargetPartnerOrClientAdmin,
    targetUserPartnerId,
    targetUserClientIds,
    canManageUsers,
    hasTargetAccessToAccessCodePages,
    hasTargetAccessToEligibilityFilesPage,
    hasTargetAccessToAdministrationPages,
    hasTargetAccessToRegistrationPages,
    hasTargetAccessToMultipleClients,
    isTargetUserClientAdmin,
    isTargetUserNonPhiAdmin,
    isNonPhiAdmin,
    isCurrentUserHCPAdmin,
    isCurrentUserNonPhiAdmin,
    isEverlywellSuperAdminClient,
    isEverlywellSuperAdminPartner,
  };
}

export default useSuperAdmin
