import { useContext } from "react"
import { useMutation } from "react-apollo"

import { SessionContext } from "contexts/session"

import { queryOptions } from "utils/helper"

import ACTIVATE_USER from "../../graphql/UserManagement/mutations/activateUser"

const useActivateUserMutation = options => {
  const { user } = useContext(SessionContext)

  return useMutation(ACTIVATE_USER, {
    ...queryOptions(user, true),
    ...options,
    refetchQueries: ['FindOneUser'],
  });
}

export default useActivateUserMutation
