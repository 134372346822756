import { colors, size, CheckMark, Warning, Error } from '@everlywell/leaves';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'EW Nexa', sans-serif;
`;

const Column = styled.div`
  margin-left: ${size.xs1}px;
`;

const Title = styled.div`
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: 0.27px;
  color: ${colors.gray5};
  margin-bottom: ${size.xs3}px;
`;

const Content = styled.div`
  font-size: 0.889rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: 0.5px;
  color: ${colors.gray4};
  margin-bottom: 0.125rem;
`;

const Toast = ({ message, description, onClick, type = 'success' }) => {
  const resolveIcon = () => {
    switch (type) {
      case 'error':
        return <Error height={16} width={16} />;
      case 'warning':
        return <Warning height={16} width={16} />;
      default:
        return <CheckMark height={16} width={16} />;
    }
  };

  return (
    <Container onClick={onClick}>
      <div id="toast-icon">{resolveIcon()}</div>
      <Column>
        <Title>{message}</Title>
        <Content>{description}</Content>
      </Column>
    </Container>
  );
};

export default Toast;
