import { InMemoryCache } from "apollo-cache-inmemory"
import { defaultDataIdFromObject } from '@apollo/client';

const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'lens_kit_results_view':
        return `lens_kit_results_view:${responseObject.id}:${responseObject.enterprise_program_name}:${responseObject.test_display_name}`;
      case 'tests_names_view':
        return `tests_names_view:${responseObject.id}:${responseObject.display_name}`;
      default:
        return defaultDataIdFromObject(responseObject);
    }
  }
})

export default cache
